import { CustomDialog } from "@toolkit/ui";
import React, { FC, useEffect, useState } from "react";
import { useGuidedCareJourneyInterventionsActivitiesModalStyle } from "./useGuidedCareJourneyInterventionsActivitiesModalStyle";
import { TemplateIntervention } from "@health/queries/types";
import {
  GuidedCareJourneyInterventionsActivities,
  IGuidedCareJourneyInterventionsActivitiesEvent,
} from "../../components/GuidedCareJourneyInterventions/GuidedCareJourneyInterventionsActivities/GuidedCareJourneyInterventionsActivities";

type IGuidedCareTemplateInterventionsActivitiesModalShowOptions = {
  eligibleInterventions: TemplateIntervention[];
};

type IShow = (show: boolean, showOptions?: IGuidedCareTemplateInterventionsActivitiesModalShowOptions) => void;

export const GuidedCareJourneyInterventionsActivitiesModalApi: { show: IShow } = {
  show: () => {},
};

type GuidedCareJourneyInterventionsActivitiesModalProps = {
  onChange: (event: IGuidedCareJourneyInterventionsActivitiesEvent) => void;
};

export const GuidedCareJourneyInterventionsActivitiesModal: FC<GuidedCareJourneyInterventionsActivitiesModalProps> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState<IGuidedCareTemplateInterventionsActivitiesModalShowOptions>();

  const { classes } = useGuidedCareJourneyInterventionsActivitiesModalStyle();

  const onDialogClose = () => {
    setIsOpen(false);
  };

  const show: IShow = (open, _showOptions) => {
    setIsOpen(open);
    setShowOptions(_showOptions);
  };

  useEffect(() => {
    GuidedCareJourneyInterventionsActivitiesModalApi.show = show;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      DialogTitleProps={{
        hidden: true,
      }}
      DialogContentProps={{
        classes: {
          root: classes.dialogContent,
        },
      }}
    >
      <GuidedCareJourneyInterventionsActivities
        eligibleInterventions={showOptions?.eligibleInterventions!}
        onDialogClose={onDialogClose}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
