import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getApolloContextFormToken } from "shared/utils";
import { PatientProfileComponent } from "../components/PatientProfile.screen";
import { ProfileContextProvider } from "../context/ProfileContextProvider";
import { useGetPatientProfileDataByIdLazyQuery, useGetPatientProfileDataByVisitIdLazyQuery } from "../gql";

export const PatientProfileEmbed: FC = () => {
  const [params] = useSearchParams();
  const patientId = params.get("patientId");
  const nationalId = params.get("nationalId");
  const visitId = params.get("visitId");
  const token = params.get("token");

  const [getPatientById, { data: dataByPatientId, loading: loadingByPatientId }] = useGetPatientProfileDataByIdLazyQuery({
    context: getApolloContextFormToken(token!),
  });
  const [getPatientByVisitId, { data: dataByVisitId, loading: loadingByVisitId }] = useGetPatientProfileDataByVisitIdLazyQuery({
    context: getApolloContextFormToken(token!),
  });

  const patient = dataByPatientId?.patient || dataByVisitId?.visit?.patient;
  const loading = loadingByPatientId || loadingByVisitId;

  useEffect(() => {
    if (token) {
      if (visitId) {
        getPatientByVisitId({
          variables: {
            visitId,
          },
        });
      } else if (patientId) {
        getPatientById({
          variables: {
            patientId,
            nationalId,
          },
        });
      } else {
        console.error("patientId or visitId is required");
      }
    }
  }, [patientId, nationalId, visitId, token]);

  if (token) {
    return (
      <ProfileContextProvider patientId={patient?.id} token={token!} isOpen={true}>
        {loading ? "loading..." : <PatientProfileComponent />}
      </ProfileContextProvider>
    );
  } else {
    return <>you are not authenticated</>;
  }
};
