import { OrderDirection, VendorDepartmentOrderField } from "@health/queries/types";
import { i18n, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { DepartmentQuery, useDepartmentBranchesQuery } from "../gql";
import { MedicalServicesDialog } from "./MedicalServicesDialog";
import { QRCodeButton } from "./QRCodeButton";

export type DepartmentColumnsTypes = CustomTableColumnProps<DepartmentQuery | any>[];

export const useDepartmentColumns = (): DepartmentColumnsTypes => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        type: "string",
        disableToggleVisibility: true,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: VendorDepartmentOrderField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "description",
        header: t("Description"),
        accessor: "description",
        type: "string",
        disableToggleVisibility: true,
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        type: "string",
        disableToggleVisibility: true,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: VendorDepartmentOrderField.Code,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "directions",
        header: t("Directions"),
        accessor: "directions",
        type: "string",
      },
      {
        key: "branch",
        header: t("Branch"),
        accessor: ({ branch }) => (i18n.language == "ar" && branch?.nameAr ? branch?.nameAr : branch?.name),
        type: "string",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: VendorDepartmentOrderField.Branch,
        filter: {
          type: "autocomplete",
          name: "branch",
          accessor: "branches",
          getOptionLabel: option => option?.name,
          query: useDepartmentBranchesQuery,
          getValueForBackend: selectedOption => selectedOption?.id || null,
        },
      },
      {
        key: "services",
        header: t("Medical Services"),
        accessor: ({ id }) => <MedicalServicesDialog departmentId={id} />,
        type: "string",
      },
      {
        key: "qrCode",
        header: t("QR Code"),
        accessor: ({ id, name }) => <QRCodeButton id={String(id)} name={name!} />,
      },
    ];
  }, []);
};
