import { TemplateFieldType } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useToasts } from "@toolkit/ui";
import _ from "lodash";
import { useGetGuidedCareHealthProgramQuery } from "pages/GuidedCarePatients/gql/queries";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAddMemberToProgramMutation, useEvaluateGuidedCareProgramRiskFactorTemplateMutation } from "../gql";
import { useCoPilotContext } from "./CoPilotContext";
import { replaceEscapeCharWithDot } from "./constants";
import { EvaluationResultType } from "./types";

export const useInvitePatientToProgram = (memberId?: string, handleToggle?: () => void) => {
  const { t } = useTranslation("provider");
  const [evaluationResult, setEvaluationResult] = useState<EvaluationResultType>(undefined);
  const { addToast } = useToasts();

  const { visitId } = useCoPilotContext();
  const { failed, succeeded } = useAddToast();

  const { ...methods } = useForm();

  const { handleSubmit, watch } = methods;

  const guidedCareProgramId = watch("guidedCareProgramId")?.value?.id;

  const { data, loading: isHealthProgramRiskFactorLoading } = useGetGuidedCareHealthProgramQuery({
    variables: {
      guidedCareHealthProgramId: guidedCareProgramId,
    },
    skip: !guidedCareProgramId,
  });
  const riskFactorTemplate = data?.guidedCareHealthProgram?.guidedCareHealthProgramTemplate?.riskFactorTemplate;
  const fields = riskFactorTemplate?.fields;

  useEffect(() => {
    setEvaluationResult(undefined);
  }, [guidedCareProgramId]);

  const [addMemberToProgram] = useAddMemberToProgramMutation({
    onCompleted: request => {
      const id = request?.addMemberToProgram?.id;
      if (id) {
        succeeded(t("Add Member To Program Successfully"));
        handleToggle?.();
      } else {
        failed(t("Add Member To Program Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const [evaluateGuidedCareProgramRiskFactorTemplate, { loading: isEvaluating }] = useEvaluateGuidedCareProgramRiskFactorTemplateMutation({
    onCompleted: request => {
      const isThereSuggestedPrograms = request?.evaluateGuidedCareProgramRiskFactorTemplate?.suggestedGuidedPrograms?.length;
      if (isThereSuggestedPrograms) {
        succeeded(t("Evaluate Guided Care Program Risk Factor Template Successfully"));
        handleToggle?.();
      } else {
        addToast(t("No suggested care programs"), {
          appearance: "info",
          autoDismiss: true,
        });
      }
      setEvaluationResult(request?.evaluateGuidedCareProgramRiskFactorTemplate);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const _onSubmit = data => {
    const _data = { ...data };
    const filteredObj = _.pickBy(_data, _.identity);
    delete filteredObj["guidedCareProgramId"];
    delete filteredObj["suggestedGuidedPrograms"];

    const result = Object.keys(filteredObj).reduce((acc, key) => {
      const requestKey = replaceEscapeCharWithDot(key);
      const field = fields?.find(l => l?.code === requestKey);
      if (!field) return acc;
      if (field?.type === TemplateFieldType.Boolean) {
        const selectedValue: { value: boolean; name: string } = filteredObj?.[key] as unknown as { value: boolean; name: string };
        acc[requestKey] = selectedValue?.value;
      } else if (field?.type === TemplateFieldType.String) {
        const selectedValue: { value: boolean; name: string } = filteredObj?.[key] as unknown as { value: boolean; name: string };
        acc[requestKey] = !field?.allowedValues?.length ? filteredObj[key] : selectedValue?.value;
      } else if (field) {
        acc[requestKey] = filteredObj[key];
      }

      return acc;
    }, {});

    if (riskFactorTemplate && !evaluationResult) {
      evaluateGuidedCareProgramRiskFactorTemplate({
        variables: {
          input: {
            visitId,
            memberId: memberId,
            programId: data.guidedCareProgramId?.value?.id,
            result: JSON.stringify(result),
          },
        },
      });
    } else {
      addMemberToProgram({
        variables: {
          input: {
            visitId,
            memberId: memberId,
            guidedCareProgramId: riskFactorTemplate ? data?.suggestedGuidedPrograms?.value?.id : data?.guidedCareProgramId?.value?.id,
          },
        },
      });
    }
  };

  return {
    methods,
    handleSubmit,
    _onSubmit,
    riskFactorTemplate,
    isHealthProgramRiskFactorLoading,
    evaluationResult,
    isEvaluating,
    setEvaluationResult,
  };
};
