import { Patient } from "@health/queries/types";
import { IconButton, Show, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { PatientProfile } from "shared/modules/patient";

export const PatientCareJourney: FC<{ patient: Patient; selectedTab?: string }> = ({ patient, selectedTab }) => {
  const theme = useTheme();
  return (
    <>
      <PatientProfile
        patientId={patient?.id}
        patientName={{
          firstName: patient?.firstName,
          lastName: patient?.lastName,
        }}
        defaultSelectedTab={selectedTab}
        renderModalButton={props => (
          <IconButton
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            {...props}
          >
            <Show fill={theme.palette.primary.main} />
          </IconButton>
        )}
      />
    </>
  );
};
