import { Box, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { TemplateIntervention } from "@health/queries/types";
import { useGuidedCareJourneyActiveInterventionsStyle } from "./useGuidedCareJourneyActiveInterventionsStyle";

type GuidedCareJourneyActiveInterventionsProps = {
  activeInterventions: TemplateIntervention[];
};

export const GuidedCareJourneyActiveInterventions: FC<GuidedCareJourneyActiveInterventionsProps> = props => {
  const { activeInterventions } = props;

  const { t } = useTranslation("provider");

  const { classes } = useGuidedCareJourneyActiveInterventionsStyle();

  return (
    <Box>
      <Typography className={classes.title}> {t("Goals")} </Typography>

      <Box className={classes.wrapper}>
        {activeInterventions?.map((item, index) => {
          return (
            <Box key={item?.id} className={classes.itemWrapper}>
              <Typography className={classes.text}> {pickLocalizedValue(item?.name, item?.nameAr)} </Typography>
              <Typography className={classes.text}> {item?.target} </Typography>

              {index !== activeInterventions?.length - 1 && <Box className={classes.circle} />}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
