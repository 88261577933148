import React from "react";
import { useSearchParams } from "react-router-dom";
import { Radiology } from "./Radiology";

export default function RadiologyPage(): React.ReactElement<unknown> {
  const [params] = useSearchParams();
  const visitId = params.get("visitId");
  const token = params.get("token");
  return (
    <React.Fragment>
      <Radiology token={token!} visitId={visitId!} />
    </React.Fragment>
  );
}
