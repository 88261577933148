import { makeStyles } from "@toolkit/ui";

export const useVisitCallStyles = makeStyles()(() => ({
  content: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  embed: {
    width: "100%",
    height: "100%",
    border: 0,
  },
  embedHide: {
    display: "none",
  },
  error: {
    minHeight: "100%",
    padding: "0 0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
}));
