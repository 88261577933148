import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { marketplaceOrdersInvoicesPaths, marketplaceInvoicesOrdersRoute } from "./constants/MarketPlaceInvoicesPaths";
import { ProviderMarketPlaceOrdersInvoicesContainer } from "./List/MarketPlaceOrdersInvoicesContainer";

export const marketplaceOrdersInvoicesRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({
  navigate,
  t,
}): RouteItem => {
  return {
    id: "marketplace-order-invoices-routes",
    text: t("Marketplace Orders Invoices", { ns: "provider" }),
    route: marketplaceInvoicesOrdersRoute,
    fullPath: marketplaceOrdersInvoicesPaths.mainPath.fullPath,
    element: <ProviderMarketPlaceOrdersInvoicesContainer />,
    isProhibited: !hasPermission(PermissionEnum.ManageMarketplaceOrders),
    hidden: !hasPermission(PermissionEnum.ManageMarketplaceOrders),
    onClick: (route: string) => navigate(route),
  };
};
