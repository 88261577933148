import { H_CancelReason, H_RejectionReason } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
import { get } from "lodash";

export const getReasonText = (ReasonObject: Partial<H_RejectionReason> | Partial<H_CancelReason>) => {
  const isUserLanguageArabic = i18n.language.toLowerCase() === "ar";
  if (isUserLanguageArabic) {
    return get(ReasonObject, "text_ar", get(ReasonObject, "text"));
  }
  return get(ReasonObject, "text", "-");
};
