import { Box, CircularProgress, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useGuidedCareJourneyInterventionStatisticsStyle } from "./useGuidedCareJourneyInterventionStatisticsStyle";
import { useGuidedCareTemplateInterventionPatientHealthParameterListQuery } from "../../../gql";
import { TemplateIntervention } from "@health/queries/types";
import { useProfileContext } from "../../../context/ProfileContext";

type GuidedCareJourneyInterventionStatisticsProps = {
  selectedIntervention: TemplateIntervention;
};

export const GuidedCareJourneyInterventionStatistics: FC<GuidedCareJourneyInterventionStatisticsProps> = props => {
  const { selectedIntervention } = props;

  const { t } = useTranslation("provider");
  const { patientId } = useProfileContext();

  const { classes, theme } = useGuidedCareJourneyInterventionStatisticsStyle();

  const [goalTargetValue, goalTargetUnit] = (selectedIntervention?.target || "").split(" ");

  const { data, loading } = useGuidedCareTemplateInterventionPatientHealthParameterListQuery({
    variables: {
      first: 1,
      filter: {
        codes: [selectedIntervention?.healthParameter?.code!],
        patientId: [patientId!],
      },
    },
    skip: !selectedIntervention?.healthParameter?.code || !patientId,
  });

  const patientHealthParameter = data?.patientHealthParameters?.edges?.[0]?.node?.valueNumber;

  const healthParameterUnit = pickLocalizedValue(
    selectedIntervention?.healthParameter?.unit?.display,
    selectedIntervention?.healthParameter?.unit?.arabicDisplay
  );

  return (
    <Box className={classes.root}>
      {loading ? (
        <Box className={classes.loadingWrapper}>
          <CircularProgress color={"inherit"} size={25} />
        </Box>
      ) : (
        <>
          <Typography className={classes.currentValueWrapper}>
            {t("Current Value")}:
            <Typography className={classes.currentValue}>{`${patientHealthParameter || "-"} ${healthParameterUnit || ""}`}</Typography>
          </Typography>

          <Box className={classes.circlesWrapper}>
            {goalTargetValue && (
              <Box className={classes.circleWrapper}>
                <Box className={classes.circle} color={theme.palette.success.main} borderColor={theme.palette.success.main}>
                  <Typography className={classes.circleValue}>
                    {goalTargetValue} <Typography> {goalTargetUnit}</Typography>
                  </Typography>
                </Box>

                <Typography className={classes.label}> {t("Goal")} </Typography>
              </Box>
            )}

            {selectedIntervention?.duration && (
              <Box className={classes.circleWrapper}>
                <Box className={classes.circle} color={theme.palette.primary.main} borderColor={theme.palette.primary.main}>
                  <Typography className={classes.circleValue}>
                    {selectedIntervention?.duration} <Typography> {t("Days")} </Typography>
                  </Typography>
                </Box>

                <Typography className={classes.label}> {t("Duration")} </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
