import { BranchesAutocomplete } from "@health/autocompletes";
import { VendorBranchTypeEnum } from "@health/queries/types";
import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, mapToAutocompleteOptions } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { BranchDefaultData } from "../BranchesForm.types";

type PreferredPharmaciesCardProps = {
  defaultValues: BranchDefaultData;
  hasData: boolean;
  isLoading: boolean;
};

export const PreferredPharmaciesCard: FC<PreferredPharmaciesCardProps> = props => {
  const { defaultValues, hasData, isLoading } = props;

  const { t } = useTranslation("provider");

  const form = useFormContext();

  const { setValue } = form;

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const preferredPharmacies = defaultValues?.preferredPharmacies?.length
      ? mapToAutocompleteOptions(
          defaultValues?.preferredPharmacies.map(item => ({
            id: item?.id!,
            name: item?.name!,
            nameAr: item?.nameAr!,
          })),
          "id",
          item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
        )
      : [];

    setValue("preferredPharmacies", preferredPharmacies);
  }, [defaultValues?.preferredPharmacies, setValue]);

  return (
    <>
      <FormCard doYouHaveData={hasData} loading={isLoading} title={t("Preferred Pharmacies")}>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...formGirdBreakPoints}>
            <BranchesAutocomplete
              name={"preferredPharmacies"}
              multiple
              label={t("Preferred Pharmacies")}
              filter={{
                isActive: true,
                type: [VendorBranchTypeEnum.Pharmacy],
              }}
            />
          </Grid>
        </Grid>
      </FormCard>
    </>
  );
};
