import { i18n } from "@toolkit/i18n";
import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  cardsContainer: {
    justifyContent: "flex-start",
    width: "100%",
    minHeight: "max-content",
    margin: 0,
    position: "relative",
  },
  tabsUpperContainer: {
    width: "100%",
    padding: 0,
    height: "100%",
  },
  tabsContainerClassName: {
    width: "100%",
    height: "100%",
    padding: 0,
  },
  tab: {
    border: "none",
    borderRadius: 0,
    padding: 0,
    margin: 0,
    width: "fit-content",
    height: "100%",
    cursor: "default",
    "&:not(:first-child)": {
      marginLeft: i18n.dir() === "rtl" ? "0px" : "20px",
      marginRight: i18n.dir() === "ltr" ? "0px" : "20px",
    },
    "&:last-child": {
      width: "100%!important",
      "& .card": {
        width: "100%!important",
      },
    },
  },
  navBtnClassName: {
    width: 28,
    height: 28,
    borderRadius: "100%",
    position: "absolute",
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 0.5,
    },
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    "&:first-child": {
      left: 4,
    },
    "&:last-child": {
      right: 4,
    },
    top: "calc(50% - 14px)",
    border: "3px solid",
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
  },

  navBtnContainerClassName: {
    position: "absolute",
    zIndex: 2,
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: theme.palette.common.black,
      opacity: 0.3,
    },
    width: 36,
    height: "100%",
    boxShadow: theme.mixins.shadows.md,
    "&:first-child": {
      [theme.direction === "ltr" ? "left" : "right"]: 0,
    },
    "&:last-child": {
      [theme.direction === "ltr" ? "right" : "left"]: 0,
    },
    "& svg": {
      width: 17.3,
      height: 15.625,
    },
    "&:has(button:disabled)": {
      display: "none",
    },
  },
}));
