import { Button, Grid, Typography, useTheme } from "@toolkit/ui";
import React from "react";
import { CoPilotLoading } from "../../components/Loading";
import { ShowInput } from "./LabTestResultsForm/ShowInput";
import CustomAccordion from "./CustomAccordion";
import { useTranslation } from "@toolkit/i18n";

interface RiskFactorProps {
  riskFactorTemplate;
  isHealthProgramRiskFactorLoading: boolean;
  isThereEvaluationResult: boolean;
  setEvaluationResult;
}

export const HealthProgramRiskFactorsTemplate: React.FC<RiskFactorProps> = ({
  riskFactorTemplate,
  isHealthProgramRiskFactorLoading,
  isThereEvaluationResult,
  setEvaluationResult,
}) => {
  const { t } = useTranslation("provider");
  const onEditParameter = () => setEvaluationResult(undefined);
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      {(riskFactorTemplate || isHealthProgramRiskFactorLoading) && isHealthProgramRiskFactorLoading ? (
        <CoPilotLoading />
      ) : (
        <CustomAccordion isOpen={false} label={t("Evaluated parameters")} displayWithCollapse={isThereEvaluationResult}>
          <>
            {riskFactorTemplate && !isThereEvaluationResult && (
              <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("Need risk stratification for this program")}</Typography>
            )}
            {riskFactorTemplate?.fields?.map(item => (
              <Grid item md={12} key={item?.id} marginTop={1}>
                <ShowInput
                  isSelected={false}
                  unit={item?.unit?.display!}
                  isMandatory={item?.isMandatory!}
                  code={item?.code!}
                  type={item?.type!}
                  display={item?.display!}
                  values={item?.allowedValues as string[]}
                  defaultValue={item?.defaultValue!}
                  minValue={item?.minNormalRangeValue!}
                  maxValue={item?.maxNormalRangeValue!}
                  disabled={isThereEvaluationResult}
                />
              </Grid>
            ))}
            {isThereEvaluationResult && (
              <Grid item md={12} marginTop={2}>
                <Button onClick={onEditParameter}>{t("Edit Parameters")}</Button>
              </Grid>
            )}
          </>
        </CustomAccordion>
      )}
    </Grid>
  );
};
