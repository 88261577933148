import { IMedicalFormUpsertFormValues } from "@health/domains";
import { MedicalFormInput, QuestionType } from "@health/queries/types";

export const doesQuestionHaveScores = (questionType?: QuestionType) => {
  return questionType === QuestionType.Boolean || questionType === QuestionType.SingleChoice || questionType === QuestionType.MultiChoice;
};

export const doesQuestionHaveOptions = (questionType?: QuestionType) => {
  return questionType === QuestionType.SingleChoice || questionType === QuestionType.MultiChoice;
};

export const convertProviderMedicalFormValuesToBackEndValues = (values: IMedicalFormUpsertFormValues): MedicalFormInput => {
  return {
    active: values.active!,
    name: values.name!,
    nameAr: values.nameAr!,
    code: values.code!,
    type: values?.type?.value,
    category: values?.category?.value,
    description: values.description!,
    departmentId: values.department?.value?.id!,
    medicalFormClassifications: values?.medicalFormClassifications?.map(item => ({
      name: item?.name!,
      rangeFrom: item?.rangeFrom!,
      rangeTo: item?.rangeTo!,
    })),
    questions:
      values.questions?.map(item => ({
        question: item.question!,
        questionType: item.questionType?.value!,
        required: item.required!,
        questionOptionScores: doesQuestionHaveScores(item?.questionType?.value!)
          ? item.options?.map(option => ({
              option: option?.value!,
              score: option?.score!,
            }))
          : undefined,
        options: doesQuestionHaveOptions(item?.questionType?.value) ? item.options?.map(option => option?.value!) : undefined,
      })) || [],
  };
};
