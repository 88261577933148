import { GuidedCareProgramsAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, Grid } from "@toolkit/ui";
import { ShowInput, useInvitePatientToProgram } from "pages/e-visit/CoPilot";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useProfileContext } from "../../context/ProfileContext";

export const InvitePatientToHealthProgram = () => {
  const { t } = useTranslation("provider");
  const { patient } = useProfileContext();
  const activeHealthProgramMember = patient?.activeHealthProgramMembers?.find(member => member?.isDefault || !member?.isDefault)!;
  const memberId = activeHealthProgramMember?.id!;
  const payerId = activeHealthProgramMember?.payer?.id!;

  const [open, setOpen] = React.useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const { handleSubmit, _onSubmit, riskFactorTemplate, methods } = useInvitePatientToProgram(memberId, handleToggle);

  return (
    <FormProvider {...methods}>
      <CustomDialog
        type='base'
        open={open}
        DialogTitleProps={{
          onClose: handleToggle,
          title: t("Add to care journey"),
        }}
        button={
          <Button
            disabled={!memberId}
            onClick={handleToggle}
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            {t("Add  to care journey")}
          </Button>
        }
        DialogActionsProps={{
          children: (
            <>
              <Button type='submit' onClick={handleSubmit(_onSubmit)}>
                {t("Add")}
              </Button>
              <Button onClick={handleToggle} variant='outlined'>
                {t("Close")}
              </Button>
            </>
          ),
        }}
        DialogContentProps={{
          sx: { minHeight: "unset" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GuidedCareProgramsAutocomplete
              name={"guidedCareProgramId"}
              label={t("Health Programs")}
              placeholder={t("Health Programs")}
              filter={{ payerId }}
            />
          </Grid>

          <Grid item xs={12}>
            {riskFactorTemplate &&
              riskFactorTemplate?.fields?.map(item => (
                <Grid item md={12} key={item?.id}>
                  <ShowInput
                    isSelected={false}
                    unit={item?.unit?.display!}
                    isMandatory={item?.isMandatory!}
                    code={item?.code!}
                    type={item?.type!}
                    display={item?.display!}
                    values={item?.allowedValues as string[]}
                    defaultValue={item?.defaultValue!}
                    minValue={item?.minNormalRangeValue!}
                    maxValue={item?.maxNormalRangeValue!}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </CustomDialog>
    </FormProvider>
  );
};
