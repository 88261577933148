import { FC, PropsWithChildren, useState } from "react";
import moment from "moment";
import { i18n, useTranslation } from "@toolkit/i18n";
import { Box, Tooltip } from "@toolkit/ui";
import { guidedCareActivityTypeMapper } from "@health/enum-options";
import { AppointmentStatus, GuidedCareActivityType, GuidedCareJourneyItem, GuidedCareJourneyItemStatus } from "@health/queries/types";
import { AppointmentAccept } from "pages/appointments";
import { getActivityDisplayName } from "../UpcomingActivities/utils";
import { statusMapper } from "../utils";
import { TimelineItemContent } from "./TimelineItemContent";
import { useTimeLineItemStyles } from "./useTimelineItemStyles";

const JourneyTimelineItem: FC<PropsWithChildren<{ item: GuidedCareJourneyItem }>> = ({ item, children }) => {
  const { t } = useTranslation("provider");

  const { classes, theme } = useTimeLineItemStyles();

  const currentDir = i18n.dir(i18n.language);

  const status = Object.keys(GuidedCareJourneyItemStatus).find(
    statusItem => GuidedCareJourneyItemStatus[statusItem] === item?.guidedCareJourneyItemStatus
  );

  const { title, icon } = guidedCareActivityTypeMapper(item?.guidedCareJourneyItemType!, t, theme.palette);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      disableFocusListener
      disableTouchListener
      placement={currentDir === "ltr" ? "right" : "left"}
      title={
        <>
          {(item?.guidedCareJourneyItemType === GuidedCareActivityType.HomeVisit ||
            item?.guidedCareJourneyItemType === GuidedCareActivityType.OnlineVisit ||
            item?.guidedCareJourneyItemType === GuidedCareActivityType.FacilityVisit) &&
          item?.appointment?.status === AppointmentStatus.Request ? (
            <Box key={item?.id}>
              <TimelineItemContent title={item?.id} icon='calender' />

              <AppointmentAccept
                id={item?.id}
                activeHealthProgramMember={item?.appointment?.consumer?.activeHealthProgramMembers?.[0]}
                healthProgramsByActiveMember={item?.appointment?.consumer?.healthProgramsByActiveMembers?.[0]}
                buttonEl={
                  <Box sx={{ cursor: "pointer" }}>
                    <TimelineItemContent title={moment(item?.dueDate).format("DD MMM YYYY")} icon='calender' />
                    <TimelineItemContent title={title} icon={icon} isBold />

                    <TimelineItemContent title={getActivityDisplayName(item!) || "-"} icon='user2' viewBox='-4 -4 25 25' />
                    <TimelineItemContent
                      title={status}
                      icon={statusMapper(item?.guidedCareJourneyItemStatus!)?.iconStatus}
                      color={statusMapper(item?.guidedCareJourneyItemStatus!)?.color}
                      viewBox={statusMapper(item?.guidedCareJourneyItemStatus!)?.viewBoxStatus}
                    />
                  </Box>
                }
              />
            </Box>
          ) : (
            <Box key={item?.id}>
              <TimelineItemContent title={item?.id} icon='calender' />
              <TimelineItemContent title={moment(item?.dueDate).format("DD MMM YYYY")} icon='date' />
              <TimelineItemContent title={title} icon={icon!} isBold />
              <TimelineItemContent title={getActivityDisplayName(item!) || "-"} icon='user2' viewBox='-4 -4 25 25' />

              <TimelineItemContent
                title={status}
                icon={statusMapper(item?.guidedCareJourneyItemStatus!)?.iconStatus}
                viewBox={statusMapper(item?.guidedCareJourneyItemStatus!)?.viewBoxStatus}
                color={statusMapper(item?.guidedCareJourneyItemStatus!)?.color}
              />
            </Box>
          )}
        </>
      }
      classes={{
        tooltip: classes.tooltip,
        popper: classes.popper,
      }}
    >
      <div>{children}</div>
    </Tooltip>
  );
};
export default JourneyTimelineItem;
