import { CardsContainerArrow, Grid } from "@toolkit/ui";
import { CardSkeleton } from "./CardSkeleton";
import { Tab, Tabs } from "react-tabs-scrollable";
import { i18n } from "@toolkit/i18n";
import { useStyles } from "./tabs.styles";
import EmptyCard from "../EventCard/EmptyCard";
import { FC } from "react";
import { CardsContainerProps } from "../types";
// eslint-disable-next-line no-restricted-imports
import "react-tabs-scrollable/dist/rts.css";
import "../../Cards/CardContainer/tabs.css";

export const CardsContainer: FC<CardsContainerProps> = ({ isLoadingData, cards }) => {
  const { classes, theme } = useStyles();
  const onTabClick = () => {};
  return (
    <Grid className={classes.cardsContainer} columnSpacing={2} container sx={{ flexFlow: "row" }}>
      {isLoadingData ? (
        <>
          <Grid item xs={6}>
            <CardSkeleton />
          </Grid>
          <Grid item xs={6}>
            <CardSkeleton />
          </Grid>
        </>
      ) : (
        <Tabs
          activeTab={0}
          tabsContainerClassName={classes.tabsContainerClassName}
          tabsUpperContainerClassName={classes.tabsUpperContainer}
          tabsScrollAmount={1}
          hideNavBtns={false}
          hideNavBtnsOnMobile={false}
          isRTL={i18n.dir() === "rtl"}
          onTabClick={onTabClick}
          navBtnClassName={classes.navBtnClassName}
          navBtnContainerClassName={classes.navBtnContainerClassName}
          rightBtnIcon={<CardsContainerArrow fill={theme.palette.primary.main} />}
          leftBtnIcon={<CardsContainerArrow fill={theme.palette.primary.main} direction='left' />}
        >
          {cards?.length ? (
            cards?.map((card, cardIndex) => (
              <Tab key={cardIndex} className={classes.tab}>
                {card}
              </Tab>
            ))
          ) : (
            <Tab key={0} className={classes.tab}>
              <EmptyCard />
            </Tab>
          )}
        </Tabs>
      )}
    </Grid>
  );
};
