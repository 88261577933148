import { MarketplaceOrder } from "@health/queries/types";
import { CustomDialog, CustomTable } from "@toolkit/ui";
import { FC } from "react";
import { CustomMarketplaceOrderLines } from "./types";
import { useOrderLinesColumns } from "./useOrderLinesColumns";

type ModalProps = {
  title: string;
  lines: MarketplaceOrder["orderLines"];
};

export const ProductsModal: FC<ModalProps> = ({ title, lines }) => {
  const orderItemsLines = lines.map(line => {
    const item = line?.healthPackage || line?.product;
    return {
      ...line,
      type: line?.healthPackage ? "Health Package" : "Product",
      marketPlaceItem: {
        id: item?.id,
        name: item?.name,
        nameAr: item?.nameAr,
        mainImageUrl: item?.mainImageUrl,
      },
    };
  });
  return (
    <CustomDialog type='info' title={title}>
      <CustomTable
        data={orderItemsLines as CustomMarketplaceOrderLines[]}
        columns={useOrderLinesColumns()}
        TableContainerProps={{
          sx: {
            height: 350,
          },
        }}
        hasFooter={false}
      />
    </CustomDialog>
  );
};
