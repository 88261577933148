/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderMedicalFormGetQueryVariables = Types.Exact<{
  medicalFormId: Types.Scalars['ID'];
}>;


export type ProviderMedicalFormGetQuery = { __typename?: 'Query', medicalForm?: { __typename?: 'MedicalForm', id: string, active?: boolean | null, name?: string | null, nameAr?: string | null, code?: string | null, type?: Types.MedicalFormType | null, category?: Types.MedicalFormCategory | null, description?: string | null, department?: { __typename?: 'Department', id: string, code: string, name: string } | null, medicalFormClassifications?: Array<{ __typename?: 'MedicalFormClassification', id: string, name?: string | null, rangeFrom?: number | null, rangeTo?: number | null } | null> | null, questions?: Array<{ __typename?: 'Question', id?: string | null, question?: string | null, questionType?: Types.QuestionType | null, required?: boolean | null, options?: Array<string | null> | null, questionOptionScores?: Array<{ __typename?: 'QuestionOptionScore', id?: string | null, option?: string | null, score?: number | null } | null> | null }> | null } | null };


export const ProviderMedicalFormGetDocument = gql`
    query ProviderMedicalFormGet($medicalFormId: ID!) {
  medicalForm(id: $medicalFormId) {
    id
    active
    name
    nameAr
    code
    type
    category
    description
    department {
      id
      code
      name
    }
    medicalFormClassifications {
      id
      name
      rangeFrom
      rangeTo
    }
    questions {
      id
      question
      questionType
      required
      options
      questionOptionScores {
        id
        option
        score
      }
    }
  }
}
    `;

/**
 * __useProviderMedicalFormGetQuery__
 *
 * To run a query within a React component, call `useProviderMedicalFormGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderMedicalFormGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderMedicalFormGetQuery({
 *   variables: {
 *      medicalFormId: // value for 'medicalFormId'
 *   },
 * });
 */
export function useProviderMedicalFormGetQuery(baseOptions: Apollo.QueryHookOptions<ProviderMedicalFormGetQuery, ProviderMedicalFormGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderMedicalFormGetQuery, ProviderMedicalFormGetQueryVariables>(ProviderMedicalFormGetDocument, options);
      }
export function useProviderMedicalFormGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderMedicalFormGetQuery, ProviderMedicalFormGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderMedicalFormGetQuery, ProviderMedicalFormGetQueryVariables>(ProviderMedicalFormGetDocument, options);
        }
export type ProviderMedicalFormGetQueryHookResult = ReturnType<typeof useProviderMedicalFormGetQuery>;
export type ProviderMedicalFormGetLazyQueryHookResult = ReturnType<typeof useProviderMedicalFormGetLazyQuery>;
export type ProviderMedicalFormGetQueryResult = Apollo.QueryResult<ProviderMedicalFormGetQuery, ProviderMedicalFormGetQueryVariables>;