import { GuidedCareProgramsAutocomplete } from "@health/autocompletes";
import { Button, Grid } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "@toolkit/i18n";
import { useInvitePatientToProgram } from "./useInvitePatientToProgram";
import { useCoPilotContext } from "./CoPilotContext";
import { HealthProgramRiskFactorsTemplate } from "./components/HealthProgramRiskFactorsTemplate";
import { EvaluationResult } from "./components/EvaluationResult";

type ProgramFormProps = {
  payerId: string;
};

export const ProgramForm: FC<ProgramFormProps> = ({ payerId }) => {
  const { t } = useTranslation("provider");

  const {
    handleSubmit,
    _onSubmit,
    riskFactorTemplate,
    methods,
    isHealthProgramRiskFactorLoading,
    evaluationResult,
    isEvaluating,
    setEvaluationResult,
  } = useInvitePatientToProgram();

  const guidedCareProgramId = methods?.watch("guidedCareProgramId");

  const { isThereHealthProgramMember } = useCoPilotContext();

  const isButtonDisabled =
    !guidedCareProgramId ||
    !isThereHealthProgramMember ||
    isHealthProgramRiskFactorLoading ||
    isEvaluating ||
    !!(evaluationResult && !evaluationResult?.suggestedGuidedPrograms?.length);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12}>
            <GuidedCareProgramsAutocomplete
              name={"guidedCareProgramId"}
              label={t("Health Programs")}
              disabled={!isThereHealthProgramMember}
              filter={{ isActive: true, payerId }}
            />
          </Grid>

          <HealthProgramRiskFactorsTemplate
            riskFactorTemplate={riskFactorTemplate}
            isHealthProgramRiskFactorLoading={isHealthProgramRiskFactorLoading}
            isThereEvaluationResult={!!evaluationResult}
            setEvaluationResult={setEvaluationResult}
          />
          <EvaluationResult evaluationResult={evaluationResult} />

          <Grid item xs={12}>
            <Button disabled={isButtonDisabled} type={"submit"}>
              {riskFactorTemplate?.id && !evaluationResult ? t("Evaluate") : t("Invite")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
