import { getAutocompleteFilter } from "@toolkit/ui";
import { useHealthPackagesAutocompleteQuery } from "./gql";
export const getHealthPackagesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "healthPackages",
        query: useHealthPackagesAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
        filterSearchKey: "search",
    });
};
