import { MarketplacePromotionInput } from "@health/queries/types";
import { IPromotionUpsertFormValues } from "../forms/PromotionFormUpsert/PromotionUpsertFormSchema";
import { Flatten, PromotionData, PromotionsQueryNode } from "../types";
import { createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const convertPromotionFormValuesToBackEndValues = (values: IPromotionUpsertFormValues): MarketplacePromotionInput => {
  return {
    name: values.name,
    description: values.description,
    photo: values?.photo,
    endDate: values.endDate,
    startDate: values.startDate,
    productIds: values.product?.map(product => product?.value?.id || "")!,
    productCategoryIds: values.productCategory?.map(category => category?.value?.id || "")!,
    healthPackageIds: values.healthPackage?.map(healthPackage => healthPackage?.value?.id || "")!,
    healthPackageCategoryIds: values.healthPackageCategory?.map(category => category?.value?.id || "")!,
  };
};

export const convertPromotionToFormValues = (promotion: PromotionsQueryNode): IPromotionUpsertFormValues => {
  return {
    name: promotion?.name!,
    description: promotion?.description,
    photo: promotion?.photo,
    endDate: promotion?.endDate,
    startDate: promotion?.startDate,
    product: promotion?.products?.map(product => createProductAutocompleteObject(product))!,
    productCategory: promotion?.productCategories?.map(product => createProductCategoryAutocompleteObject(product!))!,
    healthPackage: promotion?.healthPackages?.map(healthPackage => createHealthPackageAutocompleteObject(healthPackage))!,
    healthPackageCategory: promotion?.healthPackageCategories?.map(category => createHealthPackageCategoryAutocompleteObject(category))!,
  };
};
const createProductAutocompleteObject = (product: Flatten<PromotionData["products"]>) =>
  createAutocompleteOption(
    {
      id: product?.id!,
      name: product?.name!,
      nameAr: product?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
  );

const createProductCategoryAutocompleteObject = (category: Flatten<PromotionData["productCategories"]>) =>
  createAutocompleteOption(
    {
      id: category?.id!,
      name: category?.name!,
      nameAr: category?.nameAr,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
  );

const createHealthPackageAutocompleteObject = (healthPackage: Flatten<PromotionData["healthPackages"]>) =>
  createAutocompleteOption(
    {
      id: healthPackage?.id!,
      name: healthPackage?.name!,
      nameAr: healthPackage?.nameAr,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
  );

const createHealthPackageCategoryAutocompleteObject = (category: Flatten<PromotionData["healthPackageCategories"]>) =>
  createAutocompleteOption(
    {
      id: category?.id!,
      name: category?.name!,
      nameAr: category?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
  );
