import { Box } from "@toolkit/ui";
import { GuidedCareJourneyInterventionStatistics } from "../GuidedCareJourneyInterventionStatistics/GuidedCareJourneyInterventionStatistics";
import { GuidedCareJourneyInterventionActivitiesItemsStartDate } from "../GuidedCareJourneyInterventionActivitiesItemsStartDate/GuidedCareJourneyInterventionActivitiesItemsStartDate";
import {
  GuidedCareJourneyInterventionActivitiesItems,
  IGuidedCareJourneyInterventionActivitiesItemsEvent,
} from "../GuidedCareJourneyInterventionActivitiesItems/GuidedCareJourneyInterventionActivitiesItems";
import React, { FC } from "react";
import { Moment } from "moment/moment";
import { ITemplateInterventionActivityItem } from "../../../types/types";
import { TemplateIntervention } from "@health/queries/types";

export type IGuidedCareJourneyInterventionsActivitiesTabActivitiesEvent =
  | {
      type: "CHANGE_DATE";
      payload: {
        value: Moment | null;
      };
    }
  | {
      type: "DELETE_ACTIVITY_ITEM";
      payload: {
        activityItemId: string;
      };
    };

type GuidedCareJourneyInterventionsActivitiesTabActivitiesProps = {
  selectedIntervention: TemplateIntervention;
  selectedDate: Moment | null;
  interventionActivitiesItems: ITemplateInterventionActivityItem[];
  onChange: (event: IGuidedCareJourneyInterventionsActivitiesTabActivitiesEvent) => void;
};

export const GuidedCareJourneyInterventionsActivitiesTabActivities: FC<
  GuidedCareJourneyInterventionsActivitiesTabActivitiesProps
> = props => {
  const { selectedIntervention, selectedDate, interventionActivitiesItems, onChange } = props;

  const onGuidedCareTemplateInterventionActivityItemStartDateChange = (value: Moment | null) => {
    onChange({
      type: "CHANGE_DATE",
      payload: { value },
    });
  };

  const onGuidedCareJourneyInterventionActivitiesItemsChange = (event: IGuidedCareJourneyInterventionActivitiesItemsEvent) => {
    if (event.type === "DELETE") {
      onChange({
        type: "DELETE_ACTIVITY_ITEM",
        payload: {
          activityItemId: event.payload.activityItemId,
        },
      });
    }
  };

  return (
    <Box>
      <GuidedCareJourneyInterventionStatistics selectedIntervention={selectedIntervention} />

      <Box marginBottom={2} />

      <GuidedCareJourneyInterventionActivitiesItemsStartDate
        value={selectedDate}
        onChange={onGuidedCareTemplateInterventionActivityItemStartDateChange}
      />

      <Box marginBottom={1} />

      <GuidedCareJourneyInterventionActivitiesItems
        interventionActivitiesItems={interventionActivitiesItems}
        selectedDate={selectedDate}
        onChange={onGuidedCareJourneyInterventionActivitiesItemsChange}
      />
    </Box>
  );
};
