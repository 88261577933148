import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Avatar, CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { CustomMarketplaceOrderLines } from "./types";
import { getMediaLink } from "@toolkit/core";

export type OrderLinesColumnsTypes = CustomTableColumnProps<CustomMarketplaceOrderLines>[];

export const useOrderLinesColumns = (): OrderLinesColumnsTypes => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "img",
        header: t("Image"),
        accessor: ({ marketPlaceItem }) => (
          <Avatar sx={{ width: 50, height: 50, borderRadius: "0px" }} src={getMediaLink(marketPlaceItem?.mainImageUrl || "")} />
        ),
      },
      {
        key: "type",
        header: t("Item type"),
        accessor: "type",
      },
      {
        key: "productName",
        header: t("Item Name"),
        accessor: ({ marketPlaceItem }) => pickLocalizedValue(marketPlaceItem?.name!, marketPlaceItem?.nameAr!),
      },
      {
        key: "quantity",
        header: t("Quantity"),
        accessor: "quantity",
      },
      {
        key: "price",
        header: t("Price"),
        accessor: "unitPriceNetAmount",
      },
    ];
  }, []);
};
