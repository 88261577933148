import { Box, Button, CustomIcon, InfiniteScroll, makeStyles, Menu, MenuItem, Typography } from "@toolkit/ui";
import React, { FC, useState } from "react";
import { useTranslation } from "@toolkit/i18n";

type MenuTimeLine = {
  hasMore: boolean;
  items?: Array<{ id: string; name: string }>;
  selected?: string;
  onChange?: (type) => void;
  onFetchMore: () => void;
};

export const Programs: FC<MenuTimeLine> = ({ hasMore, items, selected, onChange, onFetchMore }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useProgramsStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const selectedItem = items?.find(item => selected === item?.id);
  const isOpen = !!anchorEl;

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = type => {
    return () => {
      setAnchorEl(null);
      onChange?.(type);
    };
  };
  const next = () => {
    onFetchMore();
  };

  return (
    <Box>
      <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={theme.mixins.fonts.fontSize.md}>
        {t("Program")}
      </Typography>
      <Button
        id='patient-add-activity-menu-button'
        aria-controls={isOpen ? "patient-add-activity-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={isOpen ? "true" : undefined}
        variant='text'
        disableElevation
        onClick={handleClick}
        className={classes.btn}
        endIcon={
          <Box>
            <CustomIcon icon='expandArrow' viewBox='-5 -5 22 22' color='inherit' />
          </Box>
        }
      >
        <Box display='flex' flexDirection='column' alignItems='baseline'>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm} whiteSpace='nowrap'>
            {selectedItem?.name}
          </Typography>
        </Box>
      </Button>

      <Menu
        sx={{
          marginTop: "8px !important",
          borderRadius: "10px !important",
        }}
        id='patient-add-activity-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.paperList,
        }}
      >
        <Box id='scrollableBox' sx={{ maxHeight: 200, overflow: "auto" }}>
          <InfiniteScroll
            scrollableTarget='scrollableBox'
            dataLength={items?.length!}
            next={next}
            hasMore={hasMore}
            loader={
              <Typography fontSize={theme.mixins.fonts.fontSize.sm} p={2} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                {t("Loading...")}
              </Typography>
            }
          >
            {items?.map(item => (
              <MenuItem key={item?.id} onClick={handleSelect(item)}>
                <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{item?.name}</Typography>
              </MenuItem>
            ))}
          </InfiniteScroll>
        </Box>
      </Menu>
    </Box>
  );
};

export const useProgramsStyles = makeStyles()(theme => ({
  btn: {
    height: "35px",
    color: theme.palette.primary.main,
    marginInline: 0,
    background: theme.palette.common.white,
    borderRadius: "25px",
    border: `1px solid ${theme.palette.background.default}`,
  },
  paperList: {
    borderRadius: "10px !important",
  },
}));
