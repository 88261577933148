import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { getTranslatedNameField, useAddToast, useAutocompleteCustomHook } from "@toolkit/ui";
import { useBranchesQuery } from "gql/queries";
import { useEffect } from "react";
import { branchUserPreferenceVar } from "shared/components/BranchUser";

export const useCurrentWorkingBranchSelection = (onOpenSelectionDialog: () => void) => {
  const branchUserPreference = useReactiveVar(branchUserPreferenceVar);
  const { failed } = useAddToast();
  const { t, i18n } = useTranslation("provider");
  const getBranchName = () => {
    if (branchUserPreference?.branch?.name) return getTranslatedNameField(branchUserPreference?.branch, i18n.language);
  };
  const { data: branchesData, isLoading } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useBranchesQuery,
    input: {
      first: 10,
      filter: {
        isActive: true,
      },
    },
    dataAccessor: "branches",
  });
  const branches = branchesData?.branches?.edges?.map(({ node }) => node);

  useEffect(() => {
    if (isLoading) return;
    if (branches?.length === 0) {
      failed(t("you are not connected to any branch please contact support"));
      return;
    }
    if (branches === 1) {
      const branch = {
        branch: {
          id: branches?.[0]?.id,
          name: branches?.[0]?.name,
          nameAr: branches?.[0]?.nameAr,
        },
      };
      branchUserPreferenceVar(branch);
    } else {
      !branchUserPreference?.branch && onOpenSelectionDialog();
    }
  }, [branches?.length, isLoading]);

  return {
    getBranchName,
  };
};
