import { Box, Button } from "@toolkit/ui";
import {
  GuidedCareTemplateInterventionType,
  guidedCareTemplateInterventionTypeOptions,
} from "../../../enum-options/GuidedCareTemplateInterventionType";
import {
  GuidedCareJourneyInterventionCard,
  IGuidedCareJourneyInterventionCardEvent,
} from "../GuidedCareJourneyInterventionCard/GuidedCareJourneyInterventionCard";
import { GuidedCareJourneyAllInterventions } from "../GuidedCareJourneyAllInterventions/GuidedCareJourneyAllInterventions";
import React, { FC, useState } from "react";
import { useGuidedCareJourneyInterventionsActivitiesTabInterventionsStyle } from "./useGuidedCareJourneyInterventionsActivitiesTabInterventionsStyle";
import { TemplateIntervention } from "@health/queries/types";

export type IGuidedCareJourneyInterventionsActivitiesTabInterventionsEvent = {
  type: "INTERVENTION_SELECT";
  payload: {
    intervention: TemplateIntervention;
  };
};

type GuidedCareJourneyInterventionsActivitiesTabInterventionsProps = {
  eligibleInterventions: TemplateIntervention[];
  onChange: (event: IGuidedCareJourneyInterventionsActivitiesTabInterventionsEvent) => void;
};

export const GuidedCareJourneyInterventionsActivitiesTabInterventions: FC<
  GuidedCareJourneyInterventionsActivitiesTabInterventionsProps
> = props => {
  const { eligibleInterventions, onChange } = props;

  const [selectedTab, setSelectedTab] = useState(GuidedCareTemplateInterventionType.RecommendedInterventions);

  const { classes } = useGuidedCareJourneyInterventionsActivitiesTabInterventionsStyle();

  const onTabClick = (value: GuidedCareTemplateInterventionType) => {
    setSelectedTab(value);
  };

  const onGuidedCareJourneyInterventionCardChange = (event: IGuidedCareJourneyInterventionCardEvent) => {
    if (event.type === "CLICK") {
      onChange({
        type: "INTERVENTION_SELECT",
        payload: {
          intervention: event.payload.intervention,
        },
      });
    }
  };

  return (
    <Box>
      <Box className={classes.tabs}>
        {guidedCareTemplateInterventionTypeOptions?.map(item => (
          <Button key={item?.key} variant={item?.value === selectedTab ? "contained" : "outlined"} onClick={() => onTabClick(item?.value)}>
            {item?.label}
          </Button>
        ))}
      </Box>

      <Box>
        {selectedTab === GuidedCareTemplateInterventionType.RecommendedInterventions && (
          <>
            {eligibleInterventions?.map((item, index) => (
              <GuidedCareJourneyInterventionCard
                key={item?.id}
                index={index}
                intervention={item}
                onChange={onGuidedCareJourneyInterventionCardChange}
              />
            ))}
          </>
        )}

        {selectedTab === GuidedCareTemplateInterventionType.AllInterventions && (
          <GuidedCareJourneyAllInterventions onChange={onGuidedCareJourneyInterventionCardChange} />
        )}
      </Box>
    </Box>
  );
};
