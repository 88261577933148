import { Box, Button, CustomMuiDialogActions } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { useGuidedCareJourneyInterventionsActivitiesDialogActionsStyle } from "./useGuidedCareJourneyInterventionsActivitiesDialogActionsStyle";

export type IGuidedCareJourneyInterventionsActivitiesDialogActionsEvent = {
  type: "MERGE" | "REPLACE";
};

type GuidedCareJourneyInterventionsActivitiesDialogActionsProps = {
  isSelectedIntervention: boolean;
  onDialogClose: () => void;
  onChange: (event: IGuidedCareJourneyInterventionsActivitiesDialogActionsEvent) => void;
};

export const GuidedCareJourneyInterventionsActivitiesDialogActions: FC<
  GuidedCareJourneyInterventionsActivitiesDialogActionsProps
> = props => {
  const { isSelectedIntervention, onDialogClose, onChange } = props;

  const { t } = useTranslation("provider");

  const { classes } = useGuidedCareJourneyInterventionsActivitiesDialogActionsStyle();

  const onMergeClick = () => {
    onChange({
      type: "MERGE",
    });
  };

  const onReplaceClick = () => {
    onChange({
      type: "REPLACE",
    });
  };

  return (
    <CustomMuiDialogActions classes={{ root: classes.root }}>
      <Box className={classes.dialogActionsWrapper}>
        <Box className={classes.mainButtonWrapper}>
          {isSelectedIntervention && (
            <>
              <Button variant={"contained"} className={classes.button} onClick={onMergeClick}>
                {t("Merge")}
              </Button>

              <Button variant={"contained"} className={classes.button} onClick={onReplaceClick}>
                {t("Replace")}
              </Button>
            </>
          )}
        </Box>

        <Button variant={"outlined"} className={classes.button} onClick={onDialogClose}>
          {t("Cancel")}
        </Button>
      </Box>
    </CustomMuiDialogActions>
  );
};
