import { MarketplaceHealthPackage } from "@health/queries/types";
import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { AutocompleteController, createAutocompleteOption, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { generalInformationFields } from "./generalInformationUtils";
import { targetGenderOptions, targetGenderOptionsMap } from "@health/enum-options";
import { MarketplaceHealthPackagesCategoriesAutocomplete } from "@health/autocompletes";

interface GeneralInformationProps {
  defaultValue: MarketplaceHealthPackage;
}

export const GeneralInformation: FC<GeneralInformationProps> = ({ defaultValue }) => {
  const { t } = useTranslation("provider");
  const {
    control,
    watch,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("categoryId", {
      required: getRequiredValidation(t, !defaultValue),
    });
  }, [defaultValue, register, t]);

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const category = defaultValue?.category;
    const formattedCategory = category
      ? createAutocompleteOption({ id: category?.id, name: category?.name, nameAr: category?.nameAr }, "id", item =>
          pickLocalizedValue(item?.name, item?.nameAr || item?.name)
        )
      : undefined;

    setValue("categoryId", formattedCategory);
  }, [defaultValue, setValue]);
  return (
    <Grid container spacing={formGirdSpacing}>
      {generalInformationFields(t, defaultValue, watch)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item.name}>
          <CustomTextField
            name={item.name}
            label={item.label}
            defaultValue={item.defaultValue}
            pattern={item.pattern}
            errorsInput={errors}
            registerProps={item?.registerProps}
          />
        </Grid>
      ))}

      <Grid item xs={4}>
        <AutocompleteController
          ControllerProps={{
            name: "targetGender",
            control: control,
            rules: { required: getRequiredValidation(t, true) },
            defaultValue: targetGenderOptionsMap?.[defaultValue?.targetGender || ""] || undefined,
          }}
          TextFieldProps={{
            error: Boolean(errors?.gender?.message),
            helperText: errors?.gender?.message,
            label: t("Gender"),
          }}
          getOptionLabel={o => t(o?.label)}
          options={targetGenderOptions}
        />
      </Grid>

      <Grid item xs={4}>
        <MarketplaceHealthPackagesCategoriesAutocomplete name={"categoryId"} />
      </Grid>
    </Grid>
  );
};
