import { i18n } from "@toolkit/i18n";
import { makeStyles } from "@toolkit/ui";

export const useMedicationsSectionStyles = makeStyles()(theme => ({
  name: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  authStatus: {
    color: theme.palette.error.main,
  },
  authStatusApproved: {
    color: theme.palette.success.main,
  },
  reject: {
    fontSize: 13,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.error.main,
  },
  nameBold: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  list: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.stale.main,
  },
  medicationName: {
    margin: 0,
    padding: 0,
    position: "relative",
    "& li": {
      listStyle: "none",
    },
    "& li::marker": {
      fontSize: 26,
    },
    "& li::before": {
      content: "''",
      border: "5px solid !important",
      borderRadius: 50,
      marginTop: 5,
      marginLeft: i18n.dir(i18n.language) === "ltr" ? -15 : 0,
      marginRight: i18n.dir(i18n.language) === "rtl" ? -15 : 0,
      position: "absolute",
    },
  },
}));
