import { i18n, useTranslation } from "@toolkit/i18n";
import { CustomIcon, DatePicker, LocalizationProvider, MomentUtils, TextField } from "@toolkit/ui";
import moment from "moment-timezone";
import { Moment } from "moment/moment";
import React, { FC } from "react";

type AppointmentDateProps = {
  date: string;
  isDisable?: boolean;
  onChangeDate: (date: Moment | null) => void;
};

export const AppointmentDate: FC<AppointmentDateProps> = props => {
  const { date, onChangeDate: handleChangeDate, isDisable = false } = props;

  const { t } = useTranslation("provider");

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <DatePicker
        label={t("Date")}
        value={date}
        disabled={isDisable}
        onChange={handleChangeDate}
        components={{
          OpenPickerIcon: () => <CustomIcon icon={"date"} height={30} width={30} viewBox='-2 0 22 22' />,
        }}
        renderInput={params => (
          <TextField
            sx={{
              "& .MuiFilledInput-input": {
                textAlign: i18n.language === "ar" ? "end" : "start",
              },
            }}
            fullWidth
            placeholder={t("please choose date")}
            {...params}
          />
        )}
        minDate={moment().add(0, "d")}
      />
    </LocalizationProvider>
  );
};
