import { Accordion, AccordionDetails, AccordionSummary, MuiExpandMoreIcon, Typography } from "@toolkit/ui";
import React, { PropsWithChildren } from "react";

interface CustomAccordionProps {
  displayWithCollapse: boolean;
  isOpen: boolean;
  label: string;
  children: React.ReactNode;
}

const CustomAccordion: React.FC<PropsWithChildren<CustomAccordionProps>> = ({ displayWithCollapse, isOpen, label, children }) => {
  if (displayWithCollapse) {
    return (
      <Accordion defaultExpanded={isOpen} disableGutters elevation={0}>
        <AccordionSummary expandIcon={<MuiExpandMoreIcon />}>
          <Typography>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  } else {
    return <>{children}</>;
  }
};

export default CustomAccordion;
