import { InputMaybe, MarketplaceProduct, MarketplaceProductInput } from "@health/queries/types";
import { createAutocompleteOption } from "@toolkit/ui";
import { IProductUpsertFormValues } from "../forms/ProductFormUpsert/ProductUpsertFormSchema";
import { ProductData } from "../types";
import { pickLocalizedValue } from "@toolkit/i18n";

export const convertProductFormValuesToBackEndValues = (values: IProductUpsertFormValues): MarketplaceProductInput => {
  return {
    name: values.name,
    nameAr: values.nameAr,
    description: values.description,
    descriptionAr: values.descriptionAr,
    howToUse: values.howToUse,
    howToUseAr: values.howToUseAr,
    mainImageUrl: values.mainImageUrl,
    subImages: (values.subImages as InputMaybe<string>[]) || undefined,
    price: values.price,
    sku: values.sku,
    shortDescription: values.shortDescription,
    shortDescriptionAr: values.shortDescriptionAr,
    stockQuantity: values.stockQuantity!,
    tags: (values.tags as InputMaybe<string>[]) || undefined,
    categoryId: values.productCategory?.value?.id! || undefined,
    brandId: values.productBrand?.value?.id! || undefined,
    returnPolicy: values.returnPolicy,
    returnPolicyAr: values.returnPolicyAr,
    returnPolicyDays: values.returnPolicyDays,
    returnable: values.returnable,
  };
};

export const convertProductToFormValues = (product: ProductData): IProductUpsertFormValues => {
  return {
    name: product?.name!,
    nameAr: product?.nameAr,
    description: product?.description,
    descriptionAr: product?.descriptionAr,
    howToUse: product?.howToUse,
    howToUseAr: product?.howToUseAr,
    mainImageUrl: product?.mainImageUrl,
    subImages: product?.subImages || [],
    price: product?.price,
    sku: product?.sku,
    shortDescription: product?.shortDescription,
    shortDescriptionAr: product?.shortDescriptionAr,
    stockQuantity: product?.stockQuantity,
    tags: product?.tags || [],
    productCategory: product?.category ? createCategoryAutocompleteObject(product?.category) : undefined,
    productBrand: product?.brand ? createBrandAutocompleteObject(product?.brand) : undefined,
    returnPolicy: product?.returnPolicy,
    returnPolicyAr: product?.returnPolicyAr,
    returnPolicyDays: product?.returnPolicyDays,
    returnable: product?.returnable || false,
  };
};

export const createCategoryAutocompleteObject = (category: MarketplaceProduct["category"]) => {
  return createAutocompleteOption(
    {
      id: category?.id!,
      name: category?.name!,
      nameAr: category?.nameAr,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
  );
};

export const createBrandAutocompleteObject = (product: MarketplaceProduct["brand"]) => {
  return createAutocompleteOption(
    {
      id: product?.id!,
      name: product?.name!,
      nameAr: product?.nameAr,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
  );
};
