/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductOrdersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceOrderSortingInput>;
  filter?: Types.InputMaybe<Types.MarketplaceOrderFilterInput>;
}>;


export type GetProductOrdersQuery = { __typename?: 'Query', productOrders?: { __typename?: 'MarketplaceOrderConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, startCursor?: string | null, hasPreviousPage: boolean } | null, edges: Array<{ __typename?: 'MarketplaceOrderEdge', node: { __typename?: 'MarketplaceOrder', id: string, status: Types.MarketplaceOrderStatus, user: { __typename?: 'User', fullName?: string | null, mobile?: string | null }, orderLines: Array<{ __typename?: 'MarketplaceOrderLine', product?: { __typename?: 'MarketplaceProduct', id: string, name?: string | null, nameAr?: string | null } | null }> } }> } | null };


export const GetProductOrdersDocument = gql`
    query getProductOrders($first: Int, $after: String, $last: Int, $before: String, $sortBy: MarketplaceOrderSortingInput, $filter: MarketplaceOrderFilterInput) {
  productOrders: marketplaceOrders(
    first: $first
    after: $after
    last: $last
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    edges {
      node {
        id
        status
        user {
          fullName
          mobile
        }
        orderLines {
          product {
            id
            name
            nameAr
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductOrdersQuery__
 *
 * To run a query within a React component, call `useGetProductOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductOrdersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProductOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetProductOrdersQuery, GetProductOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductOrdersQuery, GetProductOrdersQueryVariables>(GetProductOrdersDocument, options);
      }
export function useGetProductOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductOrdersQuery, GetProductOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductOrdersQuery, GetProductOrdersQueryVariables>(GetProductOrdersDocument, options);
        }
export type GetProductOrdersQueryHookResult = ReturnType<typeof useGetProductOrdersQuery>;
export type GetProductOrdersLazyQueryHookResult = ReturnType<typeof useGetProductOrdersLazyQuery>;
export type GetProductOrdersQueryResult = Apollo.QueryResult<GetProductOrdersQuery, GetProductOrdersQueryVariables>;