import { makeStyles } from "@toolkit/ui";

export const useOrdersCardStyles = makeStyles<{ color?: string }>()((theme, { color }) => ({
  card: {
    position: "relative",
    borderRadius: "5px 10px 10px 5px",
    boxShadow: theme.mixins.shadows.sm,
  },
  text: {
    color: color || theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  icon: {
    right: 0,
    left: "initial",
    zIndex: 1,
    position: "absolute",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: theme.palette.gray.light,
    display: "flex",
    justifyContent: "center",
    width: 30,
    height: 30,
    margin: 5,
    transition: "all 1s",
    "&:hover": {
      "& svg": {
        width: 10,
        height: 26,
      },
    },
  },
  iconRtl: {
    left: 0,
    right: "initial",
  },
  paper: {
    borderRadius: "15px 10px 10px 15px",
    padding: 0,
  },
  border: {
    borderRight: theme.direction === "rtl" ? `4px solid ${color || theme.palette.primary.main}` : "none",
    borderLeft: theme.direction !== "rtl" ? `4px solid ${color || theme.palette.primary.main}` : "none",
  },
}));
