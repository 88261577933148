import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, getTranslatedNameField } from "@toolkit/ui";
import { useAssignUserADefaultBranchMutation } from "gql/mutations";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isDoctorUser } from "utils";
import BranchUserForm from "./BranchUserForm";
import { branchUserPreferenceVar } from "./branchUserReactiveVar";
import { useCurrentWorkingBranchSelectDialogStyles } from "./CurrentWorkingBranchSelectDialog.styles";
import { removeSelectedBranchFromLocalStorage, setSelectedBranchToLocalStorage } from "./utils";

export const CurrentWorkingBranchSelectDialog: FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation("provider");
  const { classes } = useCurrentWorkingBranchSelectDialogStyles();
  const { userInfo } = useOidcUserProfile();
  const [fetchAssignUserADefaultBranch, { loading }] = useAssignUserADefaultBranchMutation();
  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = methods;
  const currentUserWorkDetails = useReactiveVar(branchUserPreferenceVar);
  const handleClose = (event?: object) => {
    if (event === undefined) {
      onClose();
    }
  };
  const onSubmit = async data => {
    if (data?.branch) {
      const dataBranch = {
        branch: data?.branch && {
          id: data?.branch?.id,
          name: data?.branch?.name,
          nameAr: data?.branch?.nameAr,
        },
        city: data?.city && {
          id: data?.city?.value?.id,
          name: getTranslatedNameField(data?.city?.value, i18n.language) || data?.city?.value?.name,
        },
      };
      await fetchAssignUserADefaultBranch({
        variables: {
          branchId: data?.branch?.id!,
        },
      });
      branchUserPreferenceVar(dataBranch);
      setSelectedBranchToLocalStorage(JSON.stringify(dataBranch));
    } else {
      removeSelectedBranchFromLocalStorage();
      branchUserPreferenceVar(undefined);
    }
    onClose();
  };

  return (
    <CustomDialog
      type='base'
      open={Boolean(isOpen)}
      classes={{
        paper: classes.paper,
      }}
      onClose={handleClose}
      DialogTitleProps={{
        title: t("Select Branch User"),
        hasCloseButton: !!(currentUserWorkDetails && isDoctorUser(userInfo) && currentUserWorkDetails.branch),
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={loading} className={classes.selectBtn} onClick={handleSubmit(onSubmit)}>
            {t("Select")}
          </Button>
        ),
      }}
    >
      <FormProvider {...methods}>
        <form>
          <Box
            sx={{
              margin: "15px 0px",
            }}
          >
            <BranchUserForm />
          </Box>
        </form>
      </FormProvider>
    </CustomDialog>
  );
};
