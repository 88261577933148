/* eslint-disable jsx-a11y/iframe-has-title */
import { CircularProgress } from "@toolkit/ui";
import { FC } from "react";
import { useVisitCallStyles } from "./VisitCall.styles";
import { VisitCallError } from "./VisitCallError";
import { useVisitCallEmbedHook } from "./useVisitCallEmbedHook";

type VisitCallEmbedProps = {
  src: string;
  token: string;
  onRequestClose?: (closeWindow?: boolean) => void;
};

export const VisitCallEmbed: FC<VisitCallEmbedProps> = ({ token, src, onRequestClose }) => {
  const { classes } = useVisitCallStyles();
  const { ref, isReady, hasError, onLoad, onError, onRetry } = useVisitCallEmbedHook({ token, onRequestClose });

  return (
    <div className={classes.content}>
      {hasError && <VisitCallError className={classes.error} onClick={onRetry}></VisitCallError>}
      {!hasError && !isReady && <CircularProgress color='primary' size={50} />}
      <iframe
        ref={ref}
        src={src}
        className={hasError || !isReady ? classes.embedHide : classes.embed}
        allow='screen-wake-lock; camera; microphone; display-capture; fullscreen; clipboard-read; clipboard-write; compute-pressure;'
        onLoad={onLoad}
        onError={onError}
      />
    </div>
  );
};
