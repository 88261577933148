import { MarketplaceDiscountCreateInput, MarketplaceDiscountType } from "@health/queries/types";
import { IDiscountUpsertFormValues } from "../forms/DiscountFormUpsert/DiscountUpsertFormSchema";
import { Flatten, DiscountData, DiscountsQueryNode } from "../types";
import { createAutocompleteOption } from "@toolkit/ui";
import { marketplaceDiscountTypeOptionsMap } from "@health/enum-options";
import { pickLocalizedValue } from "@toolkit/i18n";

export const convertDiscountFormValuesToBackEndValues = (
  values: IDiscountUpsertFormValues,
  isUpdate?: boolean
): MarketplaceDiscountCreateInput => {
  return {
    name: values.name,
    endDate: values.endDate,
    startDate: values.startDate,
    productIds: !isUpdate ? values.product?.map(product => product?.value?.id || "")! : undefined,
    healthPackageIds: !isUpdate ? values.healthPackage?.map(healthPackage => healthPackage?.value?.id || "")! : undefined,
    type: values?.type?.value!,
    amount: values?.type?.value === MarketplaceDiscountType.Fixed ? values.amount : undefined,
    percentage: values?.type?.value === MarketplaceDiscountType.Percentage ? values.percentage : undefined,
  };
};

export const convertDiscountToFormValues = (discount: DiscountsQueryNode): IDiscountUpsertFormValues => {
  return {
    name: discount?.name!,
    endDate: discount?.endDate,
    startDate: discount?.startDate,
    product: discount?.products?.map(product => createProductAutocompleteObject(product)),
    healthPackage: discount?.healthPackages?.map(healthPackage => createHealthPackageAutocompleteObject(healthPackage)),
    type: marketplaceDiscountTypeOptionsMap[discount?.type!],
    amount: discount?.amount,
    percentage: discount?.percentage || undefined,
  };
};

const createProductAutocompleteObject = (product: Flatten<DiscountData["products"]>) =>
  createAutocompleteOption(
    {
      id: product?.id!,
      name: product?.name!,
      nameAr: product?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
  );

const createHealthPackageAutocompleteObject = (healthPackage: Flatten<DiscountData["healthPackages"]>) =>
  createAutocompleteOption(
    {
      id: healthPackage?.id!,
      name: healthPackage?.name!,
      nameAr: healthPackage?.nameAr,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
  );
