import { useTranslation } from "@toolkit/i18n";
import { Box, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { Fragment, useState } from "react";
import { CurrentWorkingBranchSelectDialog } from "shared/components/BranchUser";
import { useCurrentWorkingBranchSelection } from "./CurrentWorkingBranchSelection.hook";

export const CurrentWorkingBranchSelectionItem = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const { getBranchName } = useCurrentWorkingBranchSelection(handleOpen);
  const assignedToBranch = !!getBranchName();
  return (
    <Fragment>
      <Box sx={{ marginInlineEnd: 2 }} display='flex' alignItems='center'>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: theme.mixins.fonts.fontSize.md,
            lineHeight: 1,
          }}
        >
          {assignedToBranch ? t("Your branch is: ") : ""}
        </Typography>

        <TruncateTypography
          maxWidth={250}
          lineHeight={1}
          color={theme.palette.primary.main}
          text={assignedToBranch ? getBranchName()! : t("Select Your Working Branch")}
          onClick={handleOpen}
        />
      </Box>
      <CurrentWorkingBranchSelectDialog isOpen={isOpen} onClose={handleClose} />
    </Fragment>
  );
};
