import { useReactiveVar } from "@apollo/client";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, getTranslatedNameField, Grid } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { NurseCurrentWorkSpaceDetailsVar } from "./NurseCurrentWorkSpaceDetailsVar";
import { DepartmentsFilter } from "./filters/DepartmentsFilter";
import { DoctorsFilter } from "./filters/DoctorsFilter";
import { BranchUserProps } from "./types";
import { useGetFormSelectedValues } from "./useGetFormSelectedValues.hook";
import withNurseWorkDetailsData from "./withNurseWorkDetailsData";

const NurseWorkSpaceDetailsForm: FC<BranchUserProps> = props => {
  const { t, i18n } = useTranslation("provider");
  const branchUserPreference = useReactiveVar(NurseCurrentWorkSpaceDetailsVar);
  const { selectedBranch, selectedDepartment } = useGetFormSelectedValues();
  const { language } = i18n;
  const { branches, refetch, onFetchMoreBranches, pageInfoBranches } = props;
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();
  const handleSearchInput = name => {
    refetch({ filter: { name_Icontains: name, isActive: true } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutocompleteController
          ControllerProps={{
            name: "branch",
            control: control,
            defaultValue: branchUserPreference?.branch,
            rules: { required: getRequiredValidation(t, true) },
          }}
          getOptionLabel={option => getTranslatedNameField(option, language)!}
          options={branches || []}
          onSearchInput={handleSearchInput}
          fetchMoreData={() => onFetchMoreBranches()}
          hasMore={pageInfoBranches?.hasNextPage}
          TextFieldProps={{
            placeholder: t("Branch"),
            label: t("Branch"),
            error: Boolean(formErrors?.branch?.message),
            helperText: t(formErrors?.branch?.message),
          }}
        />
      </Grid>
      {selectedBranch && (
        <>
          <Grid item xs={12}>
            <DepartmentsFilter />
          </Grid>
          {selectedDepartment && (
            <Grid item xs={12}>
              <DoctorsFilter />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default withNurseWorkDetailsData(NurseWorkSpaceDetailsForm);
