import { Box, CircularProgress } from "@toolkit/ui";
import { FC } from "react";
import { LiveSigns } from "./LiveSigns";
import { usePatientDataByVisit } from "./usePatientDataByVisit";

export const LiveSignsPage: FC = () => {
  const { patient, loadingVisit } = usePatientDataByVisit();

  return (
    <>
      {loadingVisit ? (
        <Box display='flex' justifyContent='center' m={1}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <LiveSigns patientId={patient?.id!} userId={patient?.user?.id!} />
      )}
    </>
  );
};
