import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, CardsContainerArrow, IconButton, Typography, useAddToast } from "@toolkit/ui";
import { usePatientIdByConsentCodeLazyQuery } from "pages/dashboard/gql/queries";
import { useRef, useState } from "react";
import { PatientProfile } from "shared/modules/patient";
import { VerificationInput, VerificationInputRef } from "./VerificationInput";
import { useStyles } from "./useStyles";

export const ViewPatientJourney = () => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useStyles();
  const { failed } = useAddToast();
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState<string>("");
  const OtpInputRef = useRef<VerificationInputRef>(null);
  const [getPatientMedicalProfileByConsent, { data }] = usePatientIdByConsentCodeLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      setOpen(true);
      OtpInputRef?.current?.resetInput();
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value: string) => {
    setCode(value);
  };

  const handleClick = () => {
    getPatientMedicalProfileByConsent({
      variables: {
        code,
      },
    });
  };

  return (
    <Box className={classes.card}>
      <Typography
        fontSize={theme.mixins.fonts.fontSize.xl}
        fontWeight={theme.mixins.fonts.fontWeight.semiBold}
        color={theme.palette.primary.main}
      >
        {t("View Patient Journey")}
      </Typography>
      <Box display='flex' marginTop={"13px"}>
        <VerificationInput onChange={handleChange} ref={OtpInputRef} />
        <PatientProfile
          patientId={data?.patientIdByConsentCode?.id}
          patientName={{
            firstName: data?.patientIdByConsentCode?.firstName,
            lastName: data?.patientIdByConsentCode?.lastName,
          }}
          tokenId={code}
          DrawerToggleProps={{
            isOpen: open,
            onClose: handleClose,
          }}
          renderModalButton={() => (
            <IconButton onClick={handleClick} disabled={code?.length !== 6} className={classes.iconButton}>
              <CardsContainerArrow fill={theme.palette.common.white} direction={theme.direction === "ltr" ? "right" : "left"} />
            </IconButton>
          )}
        />
      </Box>
      <Box sx={{ width: "230px" }} marginTop={"11.5px"}>
        <Typography
          sx={{
            whiteSpace: "normal",
          }}
          fontSize={theme.mixins.fonts.fontSize.xs}
          width={"fit-content"}
          fontWeight={theme.mixins.fonts.fontWeight.regular}
          textAlign='center'
          color={theme.palette.primary.main}
        >
          {t("Ask Patient for io Health Token ID in order to view their journey")}
        </Typography>
      </Box>
    </Box>
  );
};
