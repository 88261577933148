import { useTranslation } from "@toolkit/i18n";
import { ReactNode } from "react";
import { useProfileContext } from "../context/ProfileContext";
import { Assessments } from "./Assessments";
import { PatientJourney } from "./Journey";
import { PatientOverview } from "./Overview";
import { Readings } from "./Reading";
import { PatientProfileTabContainer } from "shared/modules/patient/components/PatientProfileTabContainer";
import { GuidedCareTaskList } from "pages/GuidedCareTasks/components/GuidedCareTaskList/GuidedCareTaskList";

type ProfileContentTabsReturn = {
  title: string;
  index: string;
  content: ReactNode;
  disabled?: boolean;
  display?: boolean;
};

type ProfileContentTabsProps = {
  patientUserId: string;
};

export const ProfileContentTabs: (props: ProfileContentTabsProps) => ProfileContentTabsReturn[] = props => {
  const { patientUserId } = props;

  const { t } = useTranslation("provider");

  const { hasGuidedCareHealthProgramTeam } = useProfileContext();

  return [
    {
      title: t("Overview"),
      index: "0",
      content: <PatientOverview />,
      disabled: false,
    },
    {
      title: t("Journey"),
      index: "1",
      content: <PatientJourney />,
      disabled: false,
      hidden: !hasGuidedCareHealthProgramTeam,
    },
    {
      title: t("Assessments"),
      index: "2",
      content: <Assessments />,
      disabled: false,
    },
    {
      title: t("Readings"),
      index: "3",
      content: <Readings />,
      disabled: false,
    },
    {
      title: t("Tasks"),
      index: "4",
      content: (
        <PatientProfileTabContainer
          isContainer
          isRoot
          sections={[
            {
              content: <GuidedCareTaskList patientUserId={patientUserId} isPatientColHidden />,
            },
          ]}
        />
      ),
      disabled: false,
    },
  ];
};
