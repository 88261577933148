import { useTranslation } from "@toolkit/i18n";
import { Box, Card, CardHeader, Divider, List, ListItem, Typography, useTheme } from "@toolkit/ui";
import { isEqual } from "lodash";
import { VisitDetailTab } from "pages/e-visit/VitalSigns/types";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CoPilotLoading } from "../components/Loading";
import { useDiagnosisPredictionMutation } from "../gql";
import { useCoPilotContext } from "./CoPilotContext";
import { ProgramForm } from "./ProgramForm";
import { EnrolledPrograms } from "./components/EnrolledPrograms";
import { ResultsForm } from "./components/LabTestResultsForm";
import { SuggestedPrograms } from "./components/SuggestedPrograms";

interface SuggestionsTabProps {
  payerId: string;
  isTabSelected: boolean;
  setHasUnreadSuggestedDiagnoses: Dispatch<SetStateAction<boolean>>;
}

export const SuggestionsTab: FC<SuggestionsTabProps> = ({ payerId, isTabSelected, setHasUnreadSuggestedDiagnoses }) => {
  const { t } = useTranslation("provider");
  const { ...methods } = useForm();

  const [fetchDiagnosis, { loading }] = useDiagnosisPredictionMutation();
  const [suggestedDiagnosis, setSuggestedDiagnosis] = useState<string[]>([]);
  const { selectedVisitDiagnosis, doctorNotes, selectedDetailsTab, loading: loadingFields, isLoadingStoredDetails } = useCoPilotContext();
  const isGuidedCareProgramSelected = selectedVisitDiagnosis?.riskTemplate?.isGuidedCareProgramSelected;
  const selectedGuidedCareProgram = selectedVisitDiagnosis?.riskTemplate?.selectedGuidedCareProgram;

  useEffect(() => {
    setHasUnreadSuggestedDiagnoses(false);
  }, [isTabSelected, setHasUnreadSuggestedDiagnoses]);

  useEffect(() => {
    if (!doctorNotes) return;
    fetchDiagnosis({
      variables: {
        input: {
          doctorNote: doctorNotes,
        },
      },
    }).then(({ data }) => {
      const newSuggestion = (data?.diagnosisPrediction?.diagnosis as string[]) || [];
      const hasNewSuggestion = !isEqual(newSuggestion, suggestedDiagnosis);
      if (hasNewSuggestion) {
        setSuggestedDiagnosis(newSuggestion);
        !isTabSelected && setHasUnreadSuggestedDiagnoses(true);
      }
    });
  }, [doctorNotes]);
  const theme = useTheme();
  return (
    <>
      {isTabSelected && (
        <Box>
          <ProgramForm payerId={payerId} />
          <FormProvider {...methods}>
            <Divider />
            <List>
              {selectedVisitDiagnosis && selectedDetailsTab === VisitDetailTab.Diagnosis ? (
                <>
                  {isLoadingStoredDetails || loadingFields ? (
                    <CoPilotLoading />
                  ) : (
                    <>
                      <ResultsForm />
                      {isGuidedCareProgramSelected ? (
                        <Card sx={{ mt: 1 }}>
                          <CardHeader
                            title={t("Selected care programs")}
                            titleTypographyProps={{ variant: "body1", fontWeight: theme.mixins.fonts.fontWeight.medium }}
                            sx={{
                              padding: 1,
                            }}
                          />
                          <Typography
                            sx={{
                              margin: "5px 10px",
                            }}
                          >
                            {selectedGuidedCareProgram?.name}
                          </Typography>
                        </Card>
                      ) : (
                        <>
                          <SuggestedPrograms />
                          <EnrolledPrograms />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {Boolean(suggestedDiagnosis?.length) && (
                    <>
                      <Typography variant='body2'> {t("Diagnosis")}</Typography>
                      {loading && <CoPilotLoading />}
                      {suggestedDiagnosis.map(diagnosis => {
                        return (
                          <ListItem key={diagnosis} divider>
                            {diagnosis}
                          </ListItem>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </List>
          </FormProvider>
        </Box>
      )}
    </>
  );
};
