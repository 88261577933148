import { VisitVitalSigns } from "@health/queries/types";
import { useCustomForm } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import { Box, Button, FormCard, FormNumberField, FormTextField, Grid, Typography } from "@toolkit/ui";
import { useHealthParameterVitalSignListQuery } from "pages/e-visit/VitalSigns/gql/queries";
import { convertVisitVitalSignToFormValues } from "pages/e-visit/VitalSigns/others";
import { IVitalSignUpdateFormEvent } from "pages/e-visit/VitalSigns/types";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { vitalSignsCode } from "shared/modules/patient/components/VitalSigns/utils";
import { IVitalSignsUpdateFormValues, VitalSignUpdateFormSchema, vitalSignsUpdateFormDefaultValues } from "./VitalSignUpdateFormSchema";
import { getApolloContextFormToken } from "shared/utils";

type VitalSignsUpdateFormProps = {
  token: string;
  visitVitalSigns: VisitVitalSigns[];
  isLoading?: boolean;
  onChange: (event: IVitalSignUpdateFormEvent) => void;
};

export const VitalSignUpdateForm: FC<VitalSignsUpdateFormProps> = props => {
  const { token, visitVitalSigns, isLoading, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<IVitalSignsUpdateFormValues>({
    defaultValues: vitalSignsUpdateFormDefaultValues,
    schema: VitalSignUpdateFormSchema,
  });

  const { watch, setValue, handleSubmit } = form;

  const items = watch("items");

  const noValues = items?.every(item => !item?.value);

  const { data, loading: isHealthParameterVitalSignListLoading } = useHealthParameterVitalSignListQuery({
    variables: {
      first: 10,
    },
    context: getApolloContextFormToken(token),
  });

  const healthParameterVitalSigns = data?.getHealthParameters?.edges?.map(item => item?.node);

  const isUpdateDisabled = noValues || isLoading || isHealthParameterVitalSignListLoading;

  const onSubmit = (values: IVitalSignsUpdateFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (healthParameterVitalSigns?.length) {
      const values = convertVisitVitalSignToFormValues(healthParameterVitalSigns!, visitVitalSigns);
      setValue("items", values);
    }
  }, [healthParameterVitalSigns?.length, setValue, visitVitalSigns]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormCard loading={isLoading || isHealthParameterVitalSignListLoading} doYouHaveData>
              <Grid container spacing={1}>
                {items?.map((item, index) => {
                  const display = i18n.language === "ar" ? item?.arabicDisplay! : item?.display!;

                  if (item?.hpCode === `${vitalSignsCode?.bloodPressureHigh}/${vitalSignsCode?.bloodPressureLow}`) {
                    return (
                      <Grid key={item?.hpCode} item xs={6}>
                        <FormTextField
                          name={`items.${index}.value`}
                          label={display}
                          placeholder={display}
                          InputProps={{
                            endAdornment: <Typography whiteSpace='nowrap'>{t("mmHg")}</Typography>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    );
                  }

                  return (
                    <Grid key={item?.hpCode} item xs={6}>
                      <FormNumberField
                        name={`items.${index}.value`}
                        label={display}
                        placeholder={display}
                        InputLabelProps={{ shrink: true }}
                        isFloatAllowed={item?.hpCode === vitalSignsCode?.oxygenSaturation || item?.hpCode === vitalSignsCode?.temperature}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </FormCard>
          </Grid>

          <Grid item xs={12} display='flex' justifyContent='flex-end'>
            <Box mx={2}>
              <Button type='submit' disabled={isUpdateDisabled}>
                {t("Update")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
